import React from 'react';
import Masthead from '../components/masthead';
import Copy from '../components/services/copy-columns';
import Services from '../components/services/services';
import Form from '../components/services/form';

const FreightForwardingTemplate = ({ copy, path }) => {
	const { masthead, columns, services, form } = copy;
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} />
			<Copy title={columns.title} left={columns.textLeft} img={columns.img} />
			<Services title={services.title} list={services.list} />
			<Form title={form.title} phone={form.phone} img={form.img} path={path} />
		</>
	);
};

export default FreightForwardingTemplate;
