import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FreightForwardingTemplate from '../templates/freight-forwarding-template';

const FreightForwarding = ({ data, location }) => {
	const content = data.pagesJson;
	const path = location.pathname;

	return (
		<Layout>
			<SEO title="Freight Forwarding" description={content.description} />
			<FreightForwardingTemplate copy={content} path={path} />
		</Layout>
	);
};

export default FreightForwarding;

export const pageQuery = graphql`
	query FreightForwardingPageQuery {
		pagesJson(page: { eq: "freight-forwarding" }) {
			description
			masthead {
				title
				img
			}
			...servicesCopy
			...services
			form {
				title
				phone {
					format
					raw
				}
				img
			}
		}
	}
`;
